export const MechanticItems = [
  // "new price",
  "unit price reduction",
  "percentage price reduction",
];

export const MechanicItems = [
  // "new price",
  // "unit price reduction",
  "percentage price reduction",
];
export const DisplayItems = [
  "Gondola/End Cap",
  "Free Standing",
  "Wing/Ladder Rack",
  "Dump Bin",
  "Counter Unit",
  "Check Out/Front End",
  "Shelf Only",
  "Shelf Unit (Promo/Hot Spot)",
  "Pallet",
  "Other",
];

export const tacticTypes = ["Punto de Precio", "Multiahorro"];
export const multiBuyPicklist1 = ["Buy" /* "Spend"*/];
export const multiBuyPicklist2 = [/*"Get",*/ "Save" ,"For"]; //#todo expand to For
export const multiBuyPicklist3 = ["%", "$" /*"Units", "Free"*/];
export const singleBuyPicklist = ["% off","$ Price"]; //"$ Off", "$ Price"];
export const visibilityList = ["shelf", "low", "medium", "high"];

export const tacticTypeAll = [
  /*"Single-buy - $ off",*/ "Single-buy - % off",
  /*"Single-buy - $ price",*/ "Multi-buy",
];
export const location = [
  "Store Entrance",
  "Front Aisle",
  "Back of Aisle",
  "In-aisle - with Category",
  "In-aisle - Secondary Category",
  "In-aisle - Out of Category",
  "In-aisle - Seasonal",
  "Pharmacy",
  "Pharmacy – Behind the Register",
  "Check Out/Register",
  "Front of Store (behind Check Out)",
  "Convenience only",
  "Multiple locations",
];
