import { useEffect, useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import { withFormik, FormikProps, Form } from "formik";
import {
  Button,
  TextField,
  FormControl,
  Select,
  Typography,
  MenuItem,
  Grid,
  Box,
  SelectChangeEvent,
  InputAdornment,
  CircularProgress,
  Tooltip,
  SelectProps,
  Alert,
} from "@mui/material";
import { promoSimulationFormStyles } from "./styles";
import { PromoEvent } from "../../utils/types";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { SimulationInputs, ClientData, ClientCategories } from "../../../utils/types";
import {
  tacticTypes,
  singleBuyPicklist,
  multiBuyPicklist1,
  multiBuyPicklist2,
  multiBuyPicklist3,
  visibilityList,
} from "./SelectItemMenues";
import CustomeDatePicker from "../../components/customDatePicker/CustomeDatePicker";
import theme from "utils/theme";
import moment from 'moment';
import { useGetCategoriesFromRetailer } from "utils/hooks/use.get.categories.from.retailer.hook";

export type PromoSimulationFormValues = {
  customer: string | null;
  category: string | null;
  brand: string | null;
  product: string[] | null;
  startDate: string | null;
  endDate: string | null;
  tacticTypes: string | null;
  display: string | null;
  singleBuyPick: string | null;
  multiBuyPick1: string | null;
  multiBuyPick2: string | null;
  multiBuyPick3: string | null;
  multibuyUnit: number | null;
  multibuyAmount: number | null;
  singleBuyValue: number;
  mechanic: string | null;
  depth: number | null;
  multi: boolean | null;
  quantity: number | null;
  refCal: string | null;
  // display: string | null;
};

export enum PROMO_SIMULATION_FORM_SIZE {
  SMALL = "SMALL",
  BIG = "BIG",
}

type PromoSimulationFormProps = {
  className?: string;
  size?: PROMO_SIMULATION_FORM_SIZE;
  promoEvent?: PromoEvent;
  newForm?: boolean;
  retailersData: ClientData;
  onSubmit: (values: SimulationInputs) => void;
  actionButtonsVisible?: boolean;
  multiSelect?: boolean;
  onApply?: () => void;
  onClear?: () => void;
  setRetailerIndex: Dispatch<SetStateAction<number>>;
  fetchReferenceCalendars: (selectedQuarter: string) => void;
  setRefCalendar: (id: string) => void;
  refCalendars: any[];
  refCalendarsLoad: boolean;
  refCalendar: string;
  market: string;
};

const PromoSimulationForm = (
  props: PromoSimulationFormProps & FormikProps<SimulationInputs>
) => {
  const {
    className,
    size = PROMO_SIMULATION_FORM_SIZE.SMALL,
    values,
    setFieldValue,
    retailersData,
    setRetailerIndex,
    setRefCalendar,
    fetchReferenceCalendars,
    refCalendars,
    refCalendarsLoad,
    refCalendar,
    market
  } = props;

  const [categories, setCategories] = useState<ClientCategories[]>([])
  const [categoryIndex, setCategoryIndex] = useState<number>(-1);
  const [brandIndex, setBrandIndex] = useState<number>(-1);
  const [productIndex, setProductIndex] = useState<number>(-1);
  const [validationObject, setValidationObject] = useState({});
  const classes = promoSimulationFormStyles({ size });
  const [redemptionRate, setRedemptionRate] = useState<number | null>(null);

  useEffect(() => {
    setRetailerIndex(-1);
    setFieldValue("customer", "");
    setFieldValue("category", "");
    setFieldValue("brand", "");
    setFieldValue("product", "");
    setFieldValue("endDate", null);
    setFieldValue("startDate", null);
    setFieldValue("everydayPrice", 0);
    setFieldValue("singleBuyPick", singleBuyPicklist[0]);
    setFieldValue("singleBuyValue", 0);
    setFieldValue("multiBuyPick1", multiBuyPicklist1[0]);
    setFieldValue("multiBuyPick2", multiBuyPicklist2[0]);
    setFieldValue("multiBuyPick3", multiBuyPicklist3[0]);
    setFieldValue("multibuyUnit", 2);
    setFieldValue("multibuyAmount", 1);
    setFieldValue("display", visibilityList[0]);
    setFieldValue("tacticTypes", tacticTypes[0]);
    let tempValObject = { ...validationObject };
    delete tempValObject["customer"];
    delete tempValObject["category"];
    delete tempValObject["brand"];
    delete tempValObject["product"];
    delete tempValObject["startDate"];
    delete tempValObject["endDate"];
    setFieldValue("refCal", null);

    setRefCalendar("");
    setValidationObject({ ...tempValObject });
  }, [retailersData]);

  const { isLoading: getCategoriesIsLoading, getCategoriesFromRetailer, errorMessage } = useGetCategoriesFromRetailer();

  const onSelectRetailer: SelectProps<string>["onChange"] =  async (event) => {
    setFieldValue("customer", event.target.value as string, false);
    setFieldValue("category", "")
    setFieldValue("brand", "")
    setFieldValue("product", "")
    delete validationObject["category"];
    delete validationObject["brand"];
    delete validationObject["product"];

    const newRetailerIndex = retailersData?.retailers.findIndex(x => x.internal_code === event.target.value);
    setRetailerIndex(newRetailerIndex);
    setCategoryIndex(-1);
    setBrandIndex(-1);

    setValidationObject({
      ...validationObject,
      customer: event.target.value as string,
    });

    // setFieldValue("refCal", null);
    setRefCalendar("");

    const result = await getCategoriesFromRetailer({
      retailerInternalCode: retailersData?.retailers[newRetailerIndex].internal_code,
      internalGeoCode: market
    })
    setCategories(result?.categories ?? [])
  };

  useEffect(() => {
    setValidationObject({
      ...validationObject,
      tacticTypes: tacticTypes[0],
      display: visibilityList[0],
      singleBuyPick: singleBuyPicklist[0],
      singleBuyValue: 10,
      multiBuyPick1: multiBuyPicklist1[0],
      multiBuyPick2: multiBuyPicklist2[0],
      multiBuyPick3: multiBuyPicklist3[0],
      multibuyAmount: 1,
      multibuyUnit: 2,
    });
  }, []);

  useEffect(() => {
    if (refCalendars && refCalendars.length > 0)
      setRefCalendar(refCalendars[0].id);
  }, [refCalendars]);

  useEffect(() => {
    if (values.customer)
      fetchReferenceCalendars(`Full Year ${new Date().getFullYear()}`);
  }, [values.customer]);

  const handleRefCalChange = (event: SelectChangeEvent) => {
    setRefCalendar(event.target.value as string);
  };

  const onSelectCategory: SelectProps<string>["onChange"] = (event) => {
    setFieldValue("category", event.target.value as string, false);
    setFieldValue("brand", "")
    setFieldValue("product", "")
    delete validationObject["brand"];
    delete validationObject["product"];
    setCategoryIndex(categories.findIndex(x => x.internal_code === event.target.value));
    setBrandIndex(-1);
    setProductIndex(-1);
    setValidationObject({
      ...validationObject,
      category: event.target.value as string,
    });
  };

  const onSelectBrand: SelectProps<string>["onChange"] = (event) => {
    setFieldValue("brand", event.target.value, false);
    setFieldValue("product", "")
    delete validationObject["product"];
    setBrandIndex(categories[categoryIndex]?.brands.findIndex(x => x.internal_code === event.target.value));
    setProductIndex(-1);
    setValidationObject({
      ...validationObject,
      brand: event.target.value as string,
    });
  };

  const handleVisibilityChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("display", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      display: event.target.value as string,
    });
  };



  useEffect(() => {
    /**
     * To select redeption rate we need to have selected a product and also a period
     */
    if (
      productIndex < 0 ||
      values.startDate === null ||
      values.endDate === null ||
      values.startDate >= values.endDate ||
      values.tacticTypes !== "Multiahorro" ||
      values.multibuyUnit === null ||
      values.multibuyAmount == null
    ) {
      setRedemptionRate(null);
      return;
    }
    const { rsp_list, seasonality_list } = categories[categoryIndex]?.brands[brandIndex]?.products[productIndex];
    
    /**
     * Will calculate how much % of period is in high season.
     */
    const startWeek = moment(values.startDate).isoWeek();
    const endWeek = moment(values.endDate).isoWeek();

    /**
     * If the start or the end of the selected period is inside
     * one of the high season periods, then true.
    */
   const seasonality = seasonality_list.some(x => {
     return (x.start_week <= startWeek && startWeek <= x.end_week)
     || (x.start_week <= endWeek && endWeek <= x.end_week)
    });

    const filteredRspBySeasonality = rsp_list.filter(x => x.seasonality === seasonality);

    const filteredRspByMustBuyQuantity = filteredRspBySeasonality.filter(x => {
      return x.must_buy_quantity === values.multibuyUnit
    })

    /**
     * This array should have only one item or none.
     */
    const filteredByDepth = filteredRspByMustBuyQuantity.filter(x => {
      const depth = values.multiBuyPick3 === '%' ? (values.multibuyAmount as number)
      : ( values.multibuyAmount as number) / (values.multibuyUnit as number) / x.retail_standard_price;
      const binAsString = x.promo_depth_bins.replaceAll("%", "");
      const [lower, upper] = binAsString.split("-").map(Number);
      return ((depth === 0 && lower === 0) || (lower < depth && depth <= upper))
    });

    /**
     * Now will select redemption rate based all filters.
     */
    const rsp = filteredByDepth[0] ?? { redemption_rate: rsp_list[0].redemption_rate_product, retail_standard_price: rsp_list[0].retail_standard_price };
    
    setRedemptionRate(rsp.redemption_rate);
    setFieldValue("everydayPrice", rsp.retail_standard_price);
  }, [productIndex, values.tacticTypes, values.startDate, values.endDate, values.multibuyUnit, values.multibuyAmount]);

  const handleTacticTypeChange: SelectProps<string>['onChange'] = (event) => {
    setFieldValue("tacticTypes", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      tacticTypes: event.target.value as string,
    });
  };
  const handleSingleBuyPickChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("singleBuyPick", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      singleBuyPick: event.target.value as string,
    });
  };

  const handleSingleBuyValueChange = (event: ChangeEvent<any>) => {
    setFieldValue("singleBuyValue", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      singleBuyValue: event.target.value as number,
    });
  };

  const handleMultiBuyPick1Change = (event: SelectChangeEvent<any>) => {
    setFieldValue("multiBuyPick1", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      multiBuyPick1: event.target.value as string,
    });
  };

  const handleMultiBuyPick2Change = (event: SelectChangeEvent<any>) => {
    setFieldValue("multiBuyPick2", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      multiBuyPick2: event.target.value as string,
    });
  };

  const handleMultiBuyPick3Change = (event: SelectChangeEvent<any>) => {
    setFieldValue("multiBuyPick3", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      multiBuyPick3: event.target.value as string,
    });
  };

  const handleMultibuyUnitChange = (event: ChangeEvent<any>) => {
    setFieldValue("multibuyUnit", event.target.value as Number, false);
    setValidationObject({
      ...validationObject,
      multibuyUnit: event.target.value as Number,
    });
  };

  const handleMultibuyAmountChange = (event: ChangeEvent<any>) => {
    setFieldValue("multibuyAmount", event.target.value as Number, false);
    setValidationObject({
      ...validationObject,
      multibuyAmount: event.target.value as Number,
    });
  };

  const handleDisplayChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("display", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      display: event.target.value as string,
    });
  };

  function getPromotedPrice(): string {
    const everydayPrice: number = values.everydayPrice;
    if (values.tacticTypes === "Punto de Precio") {
      if (values.singleBuyPick === "% off") {
        return `${everydayPrice * (1 - values.singleBuyValue / 100)}`;
      } else if (values.singleBuyPick === "$ Off") {
        return `${everydayPrice - values.singleBuyValue}`;
      } else {
        return `${values.singleBuyValue}`;
      }
    } else if (values.tacticTypes === tacticTypes[1]) {
      const Y: number = Number.parseInt(values.multibuyAmount + ""); // need this, otherwise will consider X and Y as string a+b == "ab"
      const X: number = Number.parseInt(values.multibuyUnit + "");

      if (X !== null && Y !== null) {
        if ("Buy" === values.multiBuyPick1) {
          // buy
          if ("Get" === values.multiBuyPick2) {
            if (
              values.multiBuyPick3 === "Units" ||
              values.multiBuyPick3 === "Free"
            ) {
              //GET, unit,
              const result = (everydayPrice * Y) / (X + Y);
              return `${result}`;
            }
          } else if (values.multiBuyPick2 === "Save") {
            return values.multiBuyPick3 === multiBuyPicklist3[1]
              ? `${Y / X}` // Reference calendars fall here Y is a value in $
              : `${everydayPrice * (1 - Y / 100)}`; //Optimized calendars fall here Y is a  %
          } else if (values.multiBuyPick2 === "For") { //#todo check if list required multiBuyPicklist2[2]
            return values.multiBuyPick3 === multiBuyPicklist3[1]
              ? `${Y / X}`
              : `${(everydayPrice * Y) / X}`;
          }
        } else {
          // Spend
          if (multiBuyPicklist2[0] === values.multiBuyPick2) {
            return `${X / Y}`;
          } else if (multiBuyPicklist2[1] === values.multiBuyPick2) {
            return values.multiBuyPick3 === multiBuyPicklist3[0]
              ? `${everydayPrice * (1 - Y / X)}`
              : `${everydayPrice * (1 - Y / 100)}`;
          }
        }
      }
    }
    return everydayPrice + "";
  }

  const onSelectProduct: SelectProps<string>["onChange"] = (event) => {
    setFieldValue("product", event.target.value, false);
    const newProductIndexValue = categories[categoryIndex]?.brands[brandIndex]?.products.findIndex(x => x.internal_product_code === event.target.value);
    setProductIndex(newProductIndexValue);
  };
  const isValidCombinaison = (
    buySpend: string | null,
    method: string | null,
    units: string | null
  ) => {
    const notValidCombaination = [
      { multiBuyPick1: "Buy", multiBuyPick2: "Save", multiBuyPick3: "Unit" },
      { multiBuyPick1: "Buy", multiBuyPick2: "Save", multiBuyPick3: "Free" },
      { multiBuyPick1: "Buy", multiBuyPick2: "For", multiBuyPick3: "%" },
      { multiBuyPick1: "Buy", multiBuyPick2: "For", multiBuyPick3: "Free" },

      { multiBuyPick1: "Spend", multiBuyPick2: "Save", multiBuyPick3: "Units" },
      { multiBuyPick1: "Spend", multiBuyPick2: "Save", multiBuyPick3: "Free" },

      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "$" },
      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "%" },
      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "Units" },
      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "Free" },
    ];
    const combinationsVali = notValidCombaination.filter(
      (item) =>
        item.multiBuyPick1 === buySpend &&
        item.multiBuyPick2 === method &&
        item.multiBuyPick3 === units
    );

    if (combinationsVali != null && combinationsVali.length > 0) return true;
    return false;
  };

  return (
    <Form className={className}>
      <Grid container className={classes.formContainer}>
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Retailer
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              value={values.customer as string}
              onChange={onSelectRetailer}
              sx={{ minWidth: 130 }}
            >
              {retailersData?.retailers.map((retailer) => (
                <MenuItem key={retailer.internal_code} value={retailer.internal_code}>
                  <Typography align="left" fontSize={12}>
                    {retailer.internal_code}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {getCategoriesIsLoading && (
          <Box display={"flex"} alignSelf={"end"} ml={2}>
            <CircularProgress size={30} />
          </Box>
        )}

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Category
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              value={values.category as string}
              onChange={onSelectCategory}
              sx={{ minWidth: 130 }}
            >
              {categories.map((category) => (
                <MenuItem key={category.internal_code} value={category.internal_code}>
                  <Typography align="left" fontSize={12}>
                    {category.internal_code}
                  </Typography>
                </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Brand
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              value={values.brand as string}
              onChange={onSelectBrand}
              sx={{ minWidth: 120 }}
            >
              {categories[categoryIndex]?.brands.map((brand) => (
                <MenuItem key={brand.internal_code} value={brand.internal_code}>
                  <Typography align="left" fontSize={12}>
                    {brand.internal_code}
                  </Typography>
                </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              productIndex >= 0 ? (
                <>
                  <Typography
                    fontSize={14}
                    color={theme.palette.primary.contrastText}
                    lineHeight={1.8}
                  >
                    High season weeks:
                    <ul>
                    {(categories[categoryIndex]?.brands[brandIndex]?.products[productIndex]?.seasonality_list ?? []).map(x => (
                      <li>{moment().set('isoWeek', x.start_week).day('Monday').format('YYYY-MM-DD')} - {moment().set('isoWeek', x.end_week).day('Sunday').format('YYYY-MM-DD')}</li>
                    ))}
                    </ul>
                  </Typography>
                </>
              ) : ''
            }
            placement="bottom"
            arrow>
            <Grid container alignItems={"center"}>
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                Product
              </Typography>
              <InfoIcon fontSize="small" sx={{ color: "text.secondary", fontSize: 14 }} />
            </Grid>
          </Tooltip>
          <FormControl>
            <Select
              className={classes.selectItem}
              sx={{ minWidth: 130 }}
              value={values.product as string}
              onChange={onSelectProduct}
            >
              {categories[categoryIndex]?.brands[brandIndex]?.products.map((product) => (
                <MenuItem key={product.internal_product_code} value={product.internal_product_code}>
                  <Typography align="left" fontSize={12}>
                    {product.internal_product_code}
                  </Typography>
                </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Display
          </Typography>

          <FormControl>
            <Select
              className={classes.specialSelectItem}
              value={values.display}
              onChange={handleVisibilityChange}
              sx={{ minWidth: 80 }}
            >
              {visibilityList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Typography align="left" fontSize={13}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Tooltip
            title={
              <Typography
                fontSize={14}
                color={theme.palette.primary.contrastText}
                lineHeight={1.8}
              >
                Price list or Retailer standard price based on Sell-out data
              </Typography>
            }
            placement="top"
            arrow
          >
            <Grid container alignItems={"center"}>
              <Typography
                fontWeight={"bold"}
                align="left"
                fontSize={13}
                mr={0.3}
              >
                Retail Standard Price
              </Typography>
              <InfoIcon
                fontSize="small"
                sx={{ color: "text.secondary", fontSize: 14 }}
              />
            </Grid>
          </Tooltip>

          <TextField
            sx={{ width: 75 }}
            InputProps={{
              style: {
                fontSize: 12,
                background: "white",
                height: 32,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h8">$</Typography>
                </InputAdornment>
              ),
            }}
            type="text"
            size="small"
            name="everyday_price"
            value={Number(values.everydayPrice.toFixed(2))}
            style={{ minWidth: "65px" }}
            disabled
          />
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Promoted Price
          </Typography>
          <TextField
            sx={{ width: 65 }}
            InputProps={{
              style: { fontSize: 12, background: "white", height: 32 },
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h8">$</Typography>
                </InputAdornment>
              ),
            }}
            type="text"
            size="small"
            name="promoted_price"
            value={parseFloat(getPromotedPrice()).toFixed(2)}
            style={{ minWidth: "87px" }}
          />
        </Grid>
        <Grid item>
          &nbsp;
          <Box className={classes.verticalLine} sx={{ marginTop: "1px" }}></Box>
        </Grid>
        <Grid item>
          <Typography
            className={classes.itemTitle}
            fontWeight={"bold"}
            align="left"
            fontSize={13}
          >
            Period
          </Typography>

          <CustomeDatePicker
            onClean={() => {
              delete validationObject["startDate"];
              delete validationObject["endDate"];
              setValidationObject({
                ...validationObject,
              });
            }}
            onChange={(date) => {
              if (date) {
                const tzoffset = date[0].getTimezoneOffset() * 60000;
                const endWithoutTimezone = new Date(
                  date[1].valueOf() - tzoffset
                )
                  .toISOString()
                  .slice(0, -1);
                const startWithoutTimezone = new Date(
                  date[0].valueOf() - tzoffset
                )
                  .toISOString()
                  .slice(0, -1);
                setFieldValue("endDate", endWithoutTimezone, false);
                setFieldValue("startDate", startWithoutTimezone, false);

                setValidationObject({
                  ...validationObject,
                  startDate: startWithoutTimezone,
                });
                setValidationObject({
                  ...validationObject,
                  endDate: endWithoutTimezone,
                });
              }
            }}
          />
        </Grid>
      </Grid> 
      {errorMessage && <Grid container direction="row">
        <Grid item><Alert severity="error" sx={{ mt:1 }}>{errorMessage}</Alert></Grid>
      </Grid>}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.formContainer2}
        columnSpacing={3}
      >
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Promo Type
          </Typography>

          <FormControl>
            <Select
              className={classes.specialSelectItem}
              value={values.tacticTypes as string}
              onChange={handleTacticTypeChange}
              sx={{ width: "142px" }}
            >
              {tacticTypes.map((item, index) => (
                <MenuItem
                key={index}
                value={item}                
                disabled={item === 'Multiahorro' && (categories[categoryIndex]?.brands[brandIndex]?.products[productIndex]?.rsp_list ?? []).length < 1} >
                  <Typography align="left" fontSize={13}>
                    {item}
                  </Typography>
                </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {values.tacticTypes === "Punto de Precio" ? (
          <Grid item className={classes.formInternalContainer2}>
            <TextField
              sx={{ width: 65 }}
              InputProps={{
                style: { fontSize: 12, background: "white", height: 32 },
                inputProps: { min: 1, max: 100 },
              }}
              type="text"
              size="small"
              name="single_buy_value"
              value={values.singleBuyValue}
              onChange={handleSingleBuyValueChange}
            />

            <FormControl sx={{ marginTop: "30px" }}>
              <Select
                className={classes.specialSelectItem}
                sx={{ width: "89px" }}
                value={values.singleBuyPick}
                onChange={handleSingleBuyPickChange}
              >
                {singleBuyPicklist.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          // multi buy
          <Grid item className={classes.formInternalContainer2}>
            <FormControl>
              <Select
                className={classes.specialSelectItem}
                sx={{ width: 10 }}
                value={values.multiBuyPick1}
                onChange={handleMultiBuyPick1Change}
              >
                {multiBuyPicklist1.map((item, index) => {
                  // but spend
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      disabled={isValidCombinaison(
                        item,
                        values.multiBuyPick2,
                        values.multiBuyPick3
                      )}
                    >
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box>
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                #{" "}
                {values.multiBuyPick1 === multiBuyPicklist1[1] ? "$" : "units"}
              </Typography>
              <TextField
                sx={{ minWidth: 65 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                  inputProps: { min: 1, max: 100 },
                }}
                type="number"
                size="small"
                name="multibuy_unit"
                value={values.multibuyUnit}
                onChange={handleMultibuyUnitChange}
                style={{ width: "48px" }}
              />
            </Box>
            <FormControl>
              <Select
                className={classes.specialSmallSelectItem}
                sx={{ width: 10 }}
                value={values.multiBuyPick2}
                onChange={handleMultiBuyPick2Change}
              >
                {multiBuyPicklist2.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      disabled={isValidCombinaison(
                        values.multiBuyPick1,
                        item,
                        values.multiBuyPick3
                      )}
                    >
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box>
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                #
              </Typography>
              <TextField
                sx={{ minWidth: 65 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                  inputProps: { min: 1, max: 100, step: "any" },
                }}
                type="number"
                size="small"
                name="multibuy_amount"
                value={values.multibuyAmount}
                onChange={handleMultibuyAmountChange}
                style={{ width: "80px" }}
              />
            </Box>
            <FormControl>
              <Select
                className={classes.specialSmallSelectItem}
                sx={{ width: 10 }}
                value={values.multiBuyPick3}
                onChange={handleMultiBuyPick3Change}
              >
                {multiBuyPicklist3.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      disabled={isValidCombinaison(
                        values.multiBuyPick1,
                        values.multiBuyPick2,
                        item
                      )}
                    >
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Display Type
          </Typography>
          <FormControl>
            <Select className={classes.specialSelectItem} value={values.display} onChange={handleDisplayChange}>
              {DisplayItems.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Typography align="left" fontSize={13}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        <Tooltip
          title={
            <Typography
              fontSize={14}
              color={theme.palette.primary.contrastText}
              lineHeight={1.8}
            >
              RR is based on product, multibuy units, discount, and season using 2 years of data. Statistical support is verified by weeks of promo per combination. If no prior promo exists, the product's RR for the retailer is shown. 
            </Typography>
          }
          placement="bottom"
          arrow>
          <Grid item>
            <Typography fontWeight={"bold"} align="left" fontSize={13}>
              Redemption rate
            </Typography>
            <TextField
              sx={{
                "& .MuiInputBase-adornedEnd": {
                  pr: 1,
                },
              }}
              InputProps={{
                style: { fontSize: 12, background: "white", height: 32 },
                inputProps: { min: 1, max: 100 },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="h8">%</Typography>
                  </InputAdornment>
                ),
              }}
              type="number"
              size="small"
              name="redemtion_rate"
              value={
                values.tacticTypes === "Punto de Precio"
                  ? 100
                  : Number.isInteger(redemptionRate)
                  ? redemptionRate
                  : Number(redemptionRate?.toFixed(1))
              }
              onChange={() => {
                return;
              }}
              disabled
            />
          </Grid>
        </Tooltip>
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Reference Calendar
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              sx={{ minWidth: 130 }}
              value={refCalendar}
              onChange={handleRefCalChange}
            >
              {refCalendars &&
                refCalendars.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      <Typography align="left" fontSize={13}>
                        {item.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        {refCalendarsLoad && (
          <Box display={"flex"} alignSelf={"end"} ml={2}>
            <CircularProgress size={30} />
          </Box>
        )}
        <Box className={classes.verticalLine} sx={{ marginTop: "42px" }}></Box>

        <Grid item sx={{ display: "flex", gap: 1, marginTop: 3.5 }}>
          <Box>
            <Button
              type="submit"
              size={"medium"}
              variant="outlined"
              className={classes.primaryButton}
              color="secondary"
              disabled={Object.keys(validationObject).length < 13}
              startIcon={<PlayArrowOutlinedIcon />}
            >
              Run simulation
            </Button>
          </Box>
          {/*<Box>*/}
          {/*<Button type="submit" size={"medium"} variant="outlined" className={classes.primaryButton} color="secondary" */}
          {/*        disabled={!(Boolean(id) && showExport)} startIcon={<IosShareIcon />} onClick={() => { handleExportClick(id) }}*/}
          {/*        sx={{ width: "97px" }}*/}
          {/*>*/}
          {/*  Export*/}
          {/*</Button>*/}
          {/*</Box> */}
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik<PromoSimulationFormProps, SimulationInputs>({
  mapPropsToValues: (props) => ({
    customer: "",
    category: "",
    brand: "",
    product: "",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    mechanic: "",
    display: visibilityList[0],
    tacticTypes: tacticTypes[0],
    singleBuyPick: singleBuyPicklist[0],
    singleBuyValue: 10,
    multiBuyPick1: multiBuyPicklist1[0],
    multiBuyPick2: multiBuyPicklist2[0],
    multiBuyPick3: multiBuyPicklist3[0],
    multibuyUnit: 2,
    multibuyAmount: 1,
    depth: 10,
    multi: false,
    quantity: 10,
    // display: "",
    everydayPrice: 0,
    referenceCalendar: "",
    market: props.market
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  displayName: "PromoSimulationForm",
})(PromoSimulationForm);
