import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Typography,
  Modal,
  FormControl,
  Button,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { ICalendarComparsionRowData } from "./interfaces";
import { Kpis } from "./kpis/kpis";
import PlotArea from "./plotarea/plotarea";
import SubBrandMenu from "./subbrandmenu/subbrandMenu";
import Timeline from "./timeline/timeline";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { useStyles } from "./calendarComparisonViewEditStyle";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import WaterfallChart from "../../../../components/waterfallChart/WaterfallChart";
import SaveIcon from "@mui/icons-material/Save";
import EventSimulationMockData from "../../../../../utils/data/EventSimulationData.json";
import CustomeDatePicker from "../../../../components/customDatePicker/CustomeDatePicker";
import { updateEventParams } from "../../../../utils/redux/recommendation/eventParams/eventParamsAction";
import theme, { KPIChartColor } from "../../../../../utils/theme";
import CalendarKpiTable from "./kpiTable/calendarKpiTable";
import { postEventSimulationActionMock } from "../../../../utils/redux/simulation/eventSimulationAction";
import {
  previewDeletedEvent,
  previewCalendar,
  setChanged as dispatchSetChanged,
  simulate,
  simulateCalendar,
  setSimulationEvent,
  CalendarViewEditActionTypes,
} from "../../../../utils/redux/calendarViewEdit/calendarViewEditAction";
import CreateEventDialog from "./dialogs/createEvent";
import {
  addDays,
  endOfWeek,
  isAfter,
  isBefore,
  parseISO,
  startOfWeek,
} from "date-fns";
import {
  DisplayItems,
  location,
  tacticTypes,
  singleBuyPicklist,
  multiBuyPicklist1,
  multiBuyPicklist2,
  multiBuyPicklist3,
  QUARTER_MAPPINGS,
} from "../../../../utils/constants";
import { formatNumber, getPromotedPrice } from "Promo/utils/mapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type CalendarComparsionViewEdit = {
  data: ICalendarComparsionRowData[];
};

const style = {
  position: "absolute",
  top: "55%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "65vw",
  height: "55vh",
  //bgcolor: "background.paper",
  borderRadius: "0.313rem",
  // boxShadow: 24,
  justifyContent: "center",
  alignItems: "center",
};
const depthSelectItem = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
];
const unitSelectItem = [
  { label: "%", value: "%" },
  { label: "$", value: "$" },
  { label: "$", value: "$" },
  { label: "% off", value: "%" },
  { label: "$ Off", value: "$" },
  { label: "$ Price", value: "$" },
];

const displaySelectItem = [
  { label: "Shelf", value: "Shelf" },
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
];

type IType = {
  calendar: any;
  referenceCalendar: any;
  selectedMarket: string;
  timelineData: any;
  clientData: any;
};

const oldestDate = function (data) {
  const date_old = data.reduce((acc, curr) => {
    if (acc === null) return new Date(curr.date_start);
    if (
      curr &&
      curr.date_start &&
      curr.date_end &&
      curr.date_start !== "" &&
      curr.date_end !== ""
    ) {
      const date = new Date(curr.date_start);
      if (date < acc) {
        return date;
      }
    }
    return acc;
  }, null);
  return date_old;
};

const newestDate = function (data) {
  const date_new = data.reduce((acc, curr) => {
    if (acc === null) return new Date(curr.date_end);
    if (
      curr &&
      curr.date_start &&
      curr.date_end &&
      curr.date_start !== "" &&
      curr.date_end !== ""
    ) {
      const date = new Date(curr.date_end);
      if (date > acc) {
        return date;
      }
    }
    return acc;
  }, null);
  return date_new;
};

const CalendarComparsionViewEdit: React.FC<IType> = ({
  calendar,
  referenceCalendar,
  selectedMarket,
  timelineData,
  clientData,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const eventParams = useAppSelector(
    (state) => state.reducer.EventParamsReducer
  );
  const data = useAppSelector(
    (state) => state.reducer.RecommendationReducer.data
  );
  const selector = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer
  );

  const [submited, setSubmited] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [previousData, setPreviousData] = useState<any>();
  const [addParametersOpen, setAddParametersOpen] = React.useState(false);
  const [addParametersCreateOpen, setAddParametersCreateOpen] =
    React.useState(false);
  const [parametersData, setParameterData] = React.useState({});
  const [openEvent, setOpenEvent] = React.useState<any>(null);
  const [openProduct, setOpenProduct] = React.useState<any>(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [paramsFlag, setParamsFlag] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [updated, setUpdated] = React.useState(true);
  const [formValues, setFormValues] = React.useState({ ...eventParams });
  const [calendarDateRange, setCalendarDateRange] = useState<any>({
    start: new Date(timelineData.timelineDateRange.start),
    end: new Date(timelineData.timelineDateRange.end_date),
  });
  const parentRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const [stickyTop, setStickyTop] = useState<number>(0);

  const handleScroll = () => {
    if (parentRef.current && stickyRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const stickyRect = stickyRef.current.getBoundingClientRect();

      // Check if the parent element is scrolled up past its top edge
      // And also check if the parent element's bottom is not yet scrolled past the sticky element's height
      if (parentRect.top <= 0 && parentRect.bottom >= stickyRect.height) {
        // Set the top position of the sticky element so that it stays in view
        setStickyTop(-parentRect.top - 8);
      } else if (parentRect.top > 0) {
        // reset the top position of the sticky element
        setStickyTop(0);
      }
    }
  };

  const handleEventParamsChange = useCallback(() => {
    setUpdated(true);
  }, [eventParams]);

  useEffect(() => {
    setFormValues({ ...eventParams });
  }, [eventParams]);

  function closeAddParameters() {
    setAddParametersOpen(false);
  }
  const handleCalendarComparsionClick = (product, left: number) => {
    setPreviousData(undefined);
    // TODO: Create Event from scratch when calling the API
    const cats = clientData.retailers.filter(
      (v) => v.internal_code === calendar.retailer
    )[0].categories;
    let brands = [];
    for (let cat of cats) {
      brands = brands.concat(...cat.brands);
    }
    let products = [];
    for (let brand of brands) {
      products = products.concat(...brand.products);
    }
    const retailer_product = products.filter(
      (prod) => prod.product_name === product
    )[0];
    setOpenProduct(retailer_product);
    setAddParametersCreateOpen(true);
  };

  const paramsForm = useForm<any>({
    mode: "onSubmit",
    defaultValues: {},
    // resolver: yupResolver(CreateScenarioParamsSchema)
  });

  // THIS EXECUTES WHEN WE CLICK RUN SIMULATION
  const handleOnSubmit = (data) => {
    if (!submited) {
      setSubmited(true);
      setUpdated(false);

      // var event = { ...openEvent };
      let event: any = {};

      event.id = Math.floor(Math.random() * -10000);
      event.date_start = eventParams.period[0];
      event.date_end = eventParams.period[1];
      // event.promo_depth = eventParams.depth;
      // event.display = eventParams.display;
      event.promo_mechanism_code =
        eventParams.multiBuyPick3 == "%"
          ? "percentage_price_reduction"
          : "unit_price_reduction";
      event.must_buy_quantity = eventParams.multiple;
      event.promo_desc =
        eventParams.tacticTypes === "Punto de Precio"
          ? eventParams.singleBuyValue + eventParams.singleBuyPick
          : eventParams.multiBuyPick1 +
            " " +
            eventParams.multibuyUnit +
            ` ${eventParams.multiBuyPick3 == "%" ? "get" : "for"} ` +
            eventParams.multibuyAmount +
            eventParams.multiBuyPick3 +
            ` ${eventParams.multiBuyPick3 == "%" ? "off" : ""}`;
      event.everydayPrice = eventParams.everydayPrice;
      const body = {
        startDate: eventParams.period[0],
        endDate: eventParams.period[1],
        // depth: eventParams.depth,
        // display: eventParams.display,
        mechanic:
          eventParams.multiBuyPick3 == "%"
            ? "percentage_price_reduction"
            : "unit_price_reduction",
        multi: eventParams.multiple > 1 ? true : false,
        quantity: eventParams.multiple,
        product_code: openEvent.internal_product_code,
        retailer_code: calendar.retailer,
        //=============
        tacticTypes: eventParams.tacticTypes,
        singleBuyPick: eventParams.singleBuyPick,
        multiBuyPick1: eventParams.multiBuyPick1,
        multibuyAmount: eventParams.multibuyAmount,
        multiBuyPick3: eventParams.multiBuyPick3,
        multiBuyPick2: eventParams.multiBuyPick2,
        everydayPrice: eventParams.everydayPrice,
        singleBuyValue: eventParams.singleBuyValue,
        multibuyUnit: eventParams.multibuyUnit,
      };

      let saveObj = { ...selector.saveObject };
      saveObj.referenceCalendarId = calendar.reference_calendar_id;
      let removeList = [...saveObj.remove];
      let addList = [...saveObj.add];
      if (!event.previewed) {
        removeList.push(openEvent.id);
        addList.push(body);
      }
      saveObj.remove = removeList;
      saveObj.add = addList;
      // dispatch(simulateCalendar(calendar.id, selectedMarket, selector.draftCalendar?.id, saveObj, openEvent.event_code));
      closeAddParameters();
      dispatch({
        type: CalendarViewEditActionTypes.UPDATE_CALENDAR_EVENT,
        payload: {
          saveObject: saveObj,
          event: event,
          product: openEvent,
        },
      });
      // setPreviousData(selector.simulation.kpis);
      // dispatch(simulate(event));
    }
  };

  // THIS EXECUTES WHEN THE MODAL OPENS
  function openAddParameters(event) {
    setDeleted(false);
    setUpdated(false);
    setOpenEvent(event);
    setSubmited(false);
    setPreviousData(undefined);
    let event_obj = {};

    if (event.is_multibuy) {
      event_obj["promo_type"] = "Multiahorro";
    } else {
      event_obj["promo_type"] = "Punto de Precio";
      event_obj["singlePick"] = "% off";
      event_obj["singleValue"] = event.promo_depth;
    }

    dispatch(
      updateEventParams({
        period: [new Date(event.date_start), new Date(event.date_end)],
        depth: event.promo_depth,
        unit:
          event.promo_mechanism_code == "percentage_price_reduction"
            ? "%"
            : event.promo_mechanism_code == "unit_price_reduction"
            ? "$"
            : "$",
        multiple: event.must_buy_quantity,
        display: event.display,
        customer: event.internal_sales_entity_code,
        category: event.category,
        product: event.internal_product_code,
        brand: event.brand,
        quantity: event.must_buy_quantity ? event.must_buy_quantity : 0,
        //======to be verified, how to  calculate these values from event object
        tacticTypes: event_obj["promo_type"],
        singleBuyPick: event_obj["singlePick"],
        multiBuyPick1: "Buy",
        multibuyAmount:
          // event.promo_mechanism_code == "percentage_price_reduction"
          event.promo_depth,
        // : Math.round(
        //     Number(event.promo_depth) * Number(event.retail_standard_price)
        //   ),
        multiBuyPick3:
          event.promo_mechanism_code == "percentage_price_reduction"
            ? "%"
            : event.promo_mechanism_code == "unit_price_reduction"
            ? "$"
            : "$",
        multiBuyPick2:
            event.promo_mechanism_code == "percentage_price_reduction"
            ? "Save"
            : event.promo_mechanism_code == "unit_price_reduction"
            ? "Save"
            : "For",
        everydayPrice: event.retail_standard_price,
        singleBuyValue: event_obj["singleValue"],
        multibuyUnit: event.must_buy_quantity,
      })
    );

    dispatch(simulate(event));
    setAddParametersOpen(true);
    dispatch(dispatchSetChanged(false));
  }

  // THIS IS EXECUTED WHEN THE PREVIEW BUTTON IS CLICKED
  const previewEvent = () => {
    if (addParametersOpen) {
      setAddParametersOpen(false);
      dispatch(previewCalendar());
    }
  };

  const deleteEvent = (
    calendarId: string,
    eventId: string,
    product: string
  ) => {
    if (addParametersOpen) {
      let saveObj = { ...selector.saveObject };
      saveObj.referenceCalendarId = calendar.reference_calendar_id;
      let removeList = [...saveObj.remove];
      removeList.push(eventId);
      saveObj.remove = removeList;

      // setDeleted(true);
      setAddParametersOpen(false);
      dispatch({
        type: CalendarViewEditActionTypes.DELETE_CALENDAR_EVENT,
        payload: { saveObject: saveObj, event: eventId, product: openEvent },
      });
      // dispatch(dispatchSetChanged(true));
      // dispatch(simulateCalendar(calendar.id, selectedMarket, selector.draftCalendar?.id, saveObj, eventId));
    }
  };

  function calculatPromotedPrice(): string {
    const result = getPromotedPrice({ ...eventParams });

    return result;
  }

  const disableNonQuarterDates = (date) => {
    const subString = calendar.quarter.split(" ");
    subString.length > 2 && subString.shift();
    const startDate = Date.parse(
      `${QUARTER_MAPPINGS[subString[0]].start}/${subString[1]}`
    );
    const endDate = Date.parse(
      `${QUARTER_MAPPINGS[subString[0]].end}/${subString[1]}`
    );
    return (
      isBefore(date, startOfWeek(startDate)) ||
      isAfter(date, endOfWeek(endDate))
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderForm = () => {
    return (
      <form
        onSubmit={paramsForm.handleSubmit(handleOnSubmit)}
        className={classes.formContainer}
      >
        <div>
          <div className={classes.formContainerInputs}>
            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Period
              </Typography>

              <Controller
                name="period"
                control={paramsForm.control}
                render={({ field }) => (
                  <CustomeDatePicker
                    {...field}
                    onChange={(event) => {
                      const tzoffset = event[0].getTimezoneOffset() * 60000;
                      const endWithoutTimezone = new Date(
                        event[1].valueOf() - tzoffset
                      )
                        .toISOString()
                        .slice(0, -1);
                      const startWithoutTimezone = new Date(
                        event[0].valueOf() - tzoffset
                      )
                        .toISOString()
                        .slice(0, -1);
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          period: [startWithoutTimezone, endWithoutTimezone],
                        })
                      );
                    }}
                    disabledDate={disableNonQuarterDates}
                    period={eventParams.period}
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Promo Type
              </Typography>
              <Select
                {...paramsForm.register("tacticTypes")}
                value={eventParams.tacticTypes}
                className={classes.displaySelect}
                sx={{ height: 32, bgcolor: "white" }}
                onChange={(event: any) => {
                  setSubmited(false);
                  dispatch(
                    updateEventParams({
                      ...eventParams,
                      tacticTypes: event.target.value,
                    })
                  );
                  setFormValues({
                    ...formValues,
                    tacticTypes: event.target.value,
                  });
                }}
              >
                {tacticTypes.map((item, index) => {
                  return (
                    <MenuItem
                      selected={item == eventParams.tacticTypes ? true : false}
                      value={item}
                      key={index}
                    >
                      <Typography fontSize={13}>{item}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {eventParams.tacticTypes === "Punto de Precio" ? (
              <>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <TextField
                    {...paramsForm.register("singleBuyValue")}
                    sx={{ minWidth: 65 }}
                    InputProps={{
                      style: { fontSize: 12, background: "white", height: 32 },
                    }}
                    type="number"
                    size="small"
                    value={eventParams.singleBuyValue}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          singleBuyValue: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        singleBuyValue: event.target.value,
                      });
                    }}
                    style={{ width: "48px" }}
                  />
                </FormControl>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <Select
                    {...paramsForm.register("singleBuyPick")}
                    value={eventParams.singleBuyPick}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          singleBuyPick: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        singleBuyPick: event.target.value,
                      });
                    }}
                  >
                    {singleBuyPicklist.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.singleBuyPick ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>

                  <Select
                    {...paramsForm.register("multiBuyPick1")}
                    value={eventParams.multiBuyPick1}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white", width: "68px" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multiBuyPick1: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multiBuyPick1: event.target.value,
                      });
                    }}
                  >
                    {multiBuyPicklist1.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.multiBuyPick1 ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    #{" "}
                    {formValues.multiBuyPick1 === multiBuyPicklist1[1]
                      ? "$"
                      : "units"}
                  </Typography>
                  <TextField
                    {...paramsForm.register("multibuyUnit")}
                    sx={{ minWidth: 65 }}
                    InputProps={{
                      style: { fontSize: 12, background: "white", height: 32 },
                    }}
                    type="number"
                    size="small"
                    value={eventParams.multibuyUnit}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multibuyUnit: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multibuyUnit: event.target.value,
                      });
                    }}
                    style={{ width: "48px" }}
                  />
                </FormControl>

                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <Select
                    {...paramsForm.register("multiBuyPick2")}
                    value={eventParams.multiBuyPick2}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white", width: "68px" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multiBuyPick2: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multiBuyPick2: event.target.value,
                      });
                    }}
                  >
                    {multiBuyPicklist2.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.multiBuyPick2 ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    #
                  </Typography>
                  <TextField
                    {...paramsForm.register("multibuyAmount")}
                    sx={{ minWidth: 65 }}
                    InputProps={{
                      inputProps: { min: 0, step: "any" },
                      style: { fontSize: 12, background: "white", height: 32 },
                    }}
                    type="number"
                    size="small"
                    value={eventParams.multibuyAmount}
                    onChange={(event: any) => {
                      setSubmited(false);

                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multibuyAmount: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multibuyAmount: event.target.value,
                      });
                    }}
                    style={{ width: "80px" }}
                  />
                </FormControl>

                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <Select
                    {...paramsForm.register("multiBuyPick3")}
                    value={eventParams.multiBuyPick3}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white", width: "68px" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multiBuyPick3: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multiBuyPick3: event.target.value,
                      });
                    }}
                  >
                    {multiBuyPicklist3.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.multiBuyPick3 ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
          </div>
          <div className={classes.formContainerInputs2}>
            {/*<FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Display
              </Typography>
              <Select
                {...paramsForm.register("display")}
                value={eventParams.display}
                className={classes.displaySelect}
                sx={{ height: 32, bgcolor: "white" }}
                onChange={(event: any) => {
                  setSubmited(false);

                  dispatch(updateEventParams({ ...eventParams, display: event.target.value }));
                }}
              >
                {displaySelectItem.map((item, index) => {
                  return (
                    <MenuItem selected={item.value == eventParams.display ? true : false} value={item.value} key={index}>
                      <Typography fontSize={13}>{item.label}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
              </FormControl> */}
            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Retail Standard Price
              </Typography>
              <TextField
                {...paramsForm.register("everydayPrice")}
                sx={{ minWidth: 87 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                }}
                type="number"
                size="small"
                value={Number(eventParams.everydayPrice).toFixed(2)}
                style={{ width: "68px" }}
                disabled
              />
            </FormControl>
            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Promoted Price
              </Typography>
              <TextField
                sx={{ minWidth: 87 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                }}
                type="number"
                size="small"
                value={parseFloat(calculatPromotedPrice()).toFixed(2)}
                style={{ width: "68px" }}
                disabled
              />
            </FormControl>

            {!deleted && (
              <div className={`flex gap-2 justify-items-end items-end`}>
                <Button
                  className={classes.primaryButton}
                  variant="outlined"
                  color="secondary"
                  disabled={selector.previewPending}
                  sx={{
                    display: submited && !openEvent.id ? "none" : "auto",
                  }}
                  onClick={() => {
                    deleteEvent(
                      calendar.id,
                      openEvent.id,
                      openEvent.internal_product_code
                    );
                  }}
                >
                  <CloseSharpIcon />
                  <Typography
                    color={
                      selector.previewPending ? "InactiveCaptionText" : "auto"
                    }
                    fontWeight={"bold"}
                    align="center"
                    fontSize={14}
                  >
                    Remove promo
                  </Typography>
                </Button>
                <Button
                  //     disabled={!updated}
                  type="submit"
                  className={classes.primaryButton}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    display: submited && !openEvent.id ? "none" : "auto",
                  }}
                >
                  <PlayArrowOutlinedIcon />
                  <Typography
                    color={"auto"}
                    fontWeight={"bold"}
                    align="center"
                    fontSize={14}
                  >
                    Apply
                  </Typography>
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    );
  };

  return (
    <Grid className="bg-white rounded-lg p-5">
      <Grid
        container
        direction="row"
        padding={2}
        pb={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid direction="column" mobile>
          <Typography variant="h2" fontWeight={"normal"} flex={3}>
            Calendar comparison
          </Typography>
          <Typography>Retailer: {calendar.retailer}</Typography>
        </Grid>
        <Grid display={"flex"}>
          <Grid display={"flex"} alignItems={"center"} direction={"column"}>
            <Grid container className="text-base text-stone-600 flex">
              <div
                className="h-4 w-4 bg-black mr-2 self-center"
                style={{ background: KPIChartColor.baseline }}
              ></div>
              Reference calendar
            </Grid>
            <Grid container className="text-base text-stone-600" pl={3}>
              {referenceCalendar && referenceCalendar.name}
            </Grid>
          </Grid>
          <Grid
            container
            width={"1.5px"}
            mx={3}
            bgcolor={theme.palette.primary.background_2}
          ></Grid>
          <Grid display={"flex"} alignItems={"center"} direction={"column"}>
            <Grid container className="text-base text-stone-600 flex">
              <div
                className="h-4 w-4 bg-black mr-2 self-center"
                style={{ background: KPIChartColor.maxMArgin }}
              ></div>
              Optimized calendar
            </Grid>
            <Grid container className="text-base text-stone-600" pl={3}>
              {calendar.name}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div ref={parentRef} className="flex min-h-fit">
        <SubBrandMenu
          calendar={calendar}
          referenceCalendar={referenceCalendar}
          timelineData={timelineData}
          ownProducts={true}
        />
        <div className="overflow-x-auto overflow-y-hidden mt-16 relative">
          <div className="pt-[16px] flex flex-col w-fit h-full">
            <Timeline
              timelineRange={calendarDateRange}
              stickyRef={stickyRef}
              topPos={stickyTop}
            />
            <PlotArea
              onAddActivity={handleCalendarComparsionClick}
              calendar={calendar}
              referenceCalendar={referenceCalendar}
              onItemClick={(event) => {
                openAddParameters(event);
              }}
              timelineRange={calendarDateRange}
              timelineData={timelineData}
              ownProducts={true}
            />
          </div>
        </div>
        <Kpis
          // timelines={}
          stickyRef={stickyRef}
          topPos={stickyTop}
          referenceCalendar={referenceCalendar}
          calendar={calendar}
          timelineData={timelineData}
          ownProducts={true}
        />
      </div>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Competitor Products
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex min-h-fit">
            <SubBrandMenu
              calendar={calendar}
              referenceCalendar={referenceCalendar}
              timelineData={timelineData}
              ownProducts={false}
            />
            <div className="overflow-x-auto overflow-y-hidden mt-16 relative">
              <div className="pt-10 flex flex-col w-fit h-full">
                <Timeline timelineRange={calendarDateRange} />
                <PlotArea
                  onAddActivity={handleCalendarComparsionClick}
                  calendar={calendar}
                  referenceCalendar={referenceCalendar}
                  onItemClick={(event) => {
                    openAddParameters(event);
                  }}
                  timelineRange={calendarDateRange}
                  timelineData={timelineData}
                  ownProducts={false}
                />
              </div>
            </div>
            <Kpis
              // timelines={}
              referenceCalendar={referenceCalendar}
              calendar={calendar}
              timelineData={timelineData}
              ownProducts={false}
            />
          </div>
        </AccordionDetails>
      </Accordion> */}

      <CreateEventDialog
        addParam={addParametersCreateOpen}
        calendar={calendar}
        selectedMarket={selectedMarket}
        openProduct={openProduct}
        timelineData={timelineData}
        onClose={() => {
          setAddParametersCreateOpen(false);
        }}
      />

      <Modal
        //  hideBackdrop={true}
        open={addParametersOpen}
        onClose={closeAddParameters}
        disableEnforceFocus={true}
      >
        <Grid container sx={{ ...style }} direction="column" rowSpacing={6}>
          <div className={classes.root}>
            <div className="flex w-full justify-between">
              <Typography variant="h2">Event parameters and KPIs</Typography>
              <CloseIcon
                onClick={closeAddParameters}
                sx={{ color: "black", cursor: "pointer" }}
              />
            </div>

            <div style={{ width: "100%" }}>{renderForm()}</div>
            {!selector.previewPending ? (
              <div>
                {
                  <>
                    <div>
                      {selector.simulation && (
                        <CalendarKpiTable
                          previousData={previousData}
                          kpis={selector.simulation.kpis}
                        />
                      )}
                    </div>
                    <br />
                    <div>
                      {/* <div style={{ height: "100%" }}>{selector.simulation && <WaterfallChart className={classes.kpiDataWidgetSection} chartEvent={selector.simulation.event} />}</div> */}
                    </div>
                  </>
                }
              </div>
            ) : (
              <div className="flex justify-items-center items-center">
                <Stack width={"100%"} alignItems="center">
                  <CircularProgress />
                </Stack>
              </div>
            )}
            <br />
            <div className={classes.saveDeletePromo}>
              <div>
                <Button
                  type="submit"
                  className={classes.savePromoButton}
                  variant="outlined"
                  disabled={!selector.changed}
                  color="secondary"
                  sx={{
                    bgcolor: "black",
                    display: `${
                      selector.changed && !selector.previewPending
                        ? "auto"
                        : "none"
                    }`,
                  }}
                  onClick={() => {
                    previewEvent();
                  }}
                >
                  <SaveIcon sx={{ color: "white" }} />
                  <Typography
                    fontWeight={"bold"}
                    align="center"
                    color={"white"}
                    px={1}
                  >
                    Preview
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default CalendarComparsionViewEdit;
